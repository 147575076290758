export const types = {
  // basic
  status: [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ],
  register: [
    { label: "By Register", value: true },
    { label: "Agent student", value: false },
  ],
  published: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  approved: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  verified: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  promotor: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  booleanYesNo: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  paymentStatus: [
    { label: "Paid", value: "paid" },
    { label: "Not Paid", value: "not_paid" },
  ],
  paymentProcess: [
    { label: "Online Payment", value: "online_payment" },
    { label: "With PromoCode", value: "with_promo" },
    { label: "Subscription", value: "subscription" },
  ],
  registerFrom: [
    { label: "Web", value: "Web" },
    { label: "Mobile", value: "Mobile" },
    { label: "Agent", value: "Agent" },
    { label: "Admin", value: "Admin" },
  ],
  type: [
    { label: "Counselling", value: "counselling" },
    { label: "Contact Us", value: "contact_us" },
    { label: "Enquiry", value: "enquiry" },
    { label: "Talk to Expert", value: "talk_to_expert" },
  ],
  gender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ],
  cashbackStatus: [
    // { label: "Waiting", value: "Waiting" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
  ],
  cashbackReqStatus: [
    { label: "Requested", value: "Requested" },
    { label: "Approved", value: "Approved" },
    { label: "Rejected", value: "Rejected" },
  ],
  marital: [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
  ],
  // university & course
  courseLevel: ["Bachelor", "Master", "Foundation", "Doctorate"],
  courseType: ["Fulltime", "Distance", "Online", "Blended"],
  courseLength: [1, 2, 3, 4, 5],
  educationLevel: [
    { label: "Grade 12 / High School", value: "highschool" },
    { label: "2-Year Undergraduate Diploma", value: "diploma" },
    {
      label: "3-Year Undergraduate Advanced Diploma",
      value: "advanced_diploma",
    },
    { label: "3-Year Bachelors Degree", value: "3_bachelor" },
    { label: "4-Year Bachelor Degree", value: "bachelor" },
    { label: "Master Degree", value: "masters" },
    { label: "Doctoral", value: "doctoral" },
  ],
  gradeLevel: [100, 80, 60, 40, 30],
  gradeValue: { 100: "A", 80: "B", 60: "C", 40: "D", 30: "E" },
  testType: ["IELTS", "TOEFL", "IELTS Ukvi"],
  test: [
    { label: "IELTS", value: "IELTS" },
    { label: "IELTS UKVI", value: "IELTS UKVI" },
    { label: "TOEFL", value: "TOEFL" },
  ],
  currency: ["GBP"],
  commission: ["Percentage", "GBP"],
  role: ["Agent", "Student", "Both"],
  elItems: [
    { label: "Percentage", value: "elPercentage" },
    { label: "Points", value: "elPoints" },
    { label: "Grade", value: "elGrade" },
    { label: "GPA", value: "elGpa" },
    { label: "CGPA", value: "elCgpa" },
  ],
  gradeScale: [
    { label: "4", value: "4" },
    { label: "5", value: "5" },
    { label: "10", value: "10" },
  ],
  promoCategory: [
    {
      label: "Course Fees",
      value: "course",
    },
    {
      label: "Application Fees",
      value: "application",
    },
  ],
  promoType: [
    {
      label: "Percentage",
      value: "percentage",
    },
    {
      label: "GBP",
      value: "GBP",
    },
  ],
  paymentCurrency: [{ label: "GBP", value: "GBP" }],
  // page
  menus: [
    {
      name: "Home",
      icon: "home-icn.svg",
      url: "/home",
      menuKey: "home",
      roleKey: "hor",
    },
    {
      name: "Agent",
      subtitle1: "Agent",
      subtitle2: "Member",
      icon: "students-icn.svg",
      url: "/agent",
      suburl1: "/agent",
      suburl2: "/member",
      icon1: "students-icn.svg",
      icon2: "students-icn.svg",
      menuKey: "agent",
      menuKey1: "agent",
      menuKey2: "member",
      roleKey: "agr",
      roleKey1: "agr",
      roleKey2: "mer",
    },
    {
      name: "Student",
      icon: "students-icn.svg",
      url: "/student",
      menuKey: "student",
      roleKey: "str",
    },
    // {
    //   name: "Member",
    //   icon: "students-icn.svg",
    //   url: "/member",
    //   menuKey: "members",
    //   roleKey: "mer",
    // },
    {
      name: "Applications",
      icon: "applications-icn.svg",
      url: "/application",
      menuKey: "application",
      roleKey: "apr",
    },
    {
      name: "Subjects",
      icon: "subjects-icn.svg",
      url: "/subject",
      menuKey: "subject",
      roleKey: "sur",
    },
    {
      name: "University",
      icon: "university-icn.svg",
      url: "/university",
      menuKey: "university",
      roleKey: "unr",
    },
    {
      name: "Courses",
      icon: "courses-icn.svg",
      url: "/course",
      menuKey: "course",
      roleKey: "cor",
    },
    {
      name: "Cashback",
      subtitle1: "Cashback",
      subtitle2: "Cashback Request",
      icon: "setup-icn.svg",
      icon1: "setup-icn.svg",
      icon2: "setup-icn.svg",
      url: "/cashback",
      suburl1: "/cashback",
      suburl2: "/cashbackrequest",
      menuKey: "cashback",
      menuKey1: "cashback",
      menuKey2: "cashreq",
      roleKey: "car",
      roleKey1: "car",
      roleKey2: "cqr",
    },
    // {
    //   name: "Cashback Request",
    //   icon: "setup-icn.svg",
    //   url: "/cashbackrequest",
    //   menuKey: "cashreq",
    //   roleKey: "cqr",
    // },
    // {
    //   name: "Promo Code",
    //   icon: "setup-icn.svg",
    //   url: "/promo",
    //   menuKey: "promo",
    //   roleKey: "pcr",
    // },
    // {
    //   name: "Users",
    //   icon: "students-icn.svg",
    //   url: "/users",
    //   menuKey: "users",
    //   roleKey: "usr",
    // },
    // {
    //   name: "Enquiry",
    //   icon: "students-icn.svg",
    //   url: "/enquiry",
    //   menuKey: "enquiry",
    //   roleKey: "eqr",
    // },
    // {
    //   name: "Contact Us",
    //   icon: "students-icn.svg",
    //   url: "/contactus",
    //   menuKey: "contactus",
    //   roleKey: "ctusr",
    // },
    // {
    //   name: "Counselling",
    //   icon: "students-icn.svg",
    //   url: "/counselling",
    //   menuKey: "counselling",
    //   roleKey: "cslgr",
    // },
    {
      name: "Consultation",
      icon: "students-icn.svg",
      url: "/consultation",
      menuKey: "consultation",
      roleKey: "cstnr",
    },
    {
      name: "Settings",
      icon: "setup-icn.svg",
      icon1: "setup-icn.svg",
      icon2: "students-icn.svg",
      icon3: "setup-icn.svg",
      icon4: "courses-icn.svg",
      url: "/setup",
      subtitle1: "Setup",
      subtitle2: "Users",
      subtitle3: "Promo Code",
      subtitle4: "Permissions",
      suburl1: "/setup",
      suburl2: "/users",
      suburl3: "/promo",
      suburl4: "/permissions",
      menuKey: "setup",
      menuKey1: "setup",
      menuKey2: "users",
      menuKey3: "promo",
      menuKey4: "permissions",
      roleKey: "ser",
      roleKey1: "ser",
      roleKey2: "usr",
      roleKey3: "pcr",
      roleKey4: "per",
    },
    {
      name: "Blog",
      subtitle1: "Blog",
      subtitle2: "Blog Category",
      icon: "courses-icn.svg",
      icon1: "courses-icn.svg",
      icon2: "courses-icn.svg",
      url: "/blog",
      suburl1: "/blog",
      suburl2: "/category",
      menuKey: "blog",
      menuKey1: "blog",
      menuKey2: "category",
      roleKey: "blr",
      roleKey1: "blr",
      roleKey2: "jcr",
    },
    // {
    //   name: "Blog Category",
    //   icon: "courses-icn.svg",
    //   url: "/category",
    //   menuKey: "category",
    //   roleKey: "bcr",
    // },
    {
      name: "Career",
      subtitle1: "Career",
      subtitle2: "Job Category",
      subtitle3: "Job Skills",
      icon: "courses-icn.svg",
      icon1: "courses-icn.svg",
      icon2: "courses-icn.svg",
      icon3: "courses-icn.svg",
      url: "/career",
      suburl1: "/career",
      suburl2: "/jobcategory",
      suburl3: "/jobskill",
      menuKey: "career",
      menuKey1: "career",
      menuKey2: "jobcategory",
      menuKey3: "jobskill",
      roleKey: "car",
      roleKey1: "car",
      roleKey2: "jcr",
      roleKey3: "jsr",
    },
    // {
    //   name: "Job Category",
    //   icon: "courses-icn.svg",
    //   url: "/jobcategory",
    //   menuKey: "jobcategory",
    //   roleKey: "jcr",
    // },
    // {
    //   name: "Job Skills",
    //   icon: "courses-icn.svg",
    //   url: "/jobskill",
    //   menuKey: "jobskill",
    //   roleKey: "jsr",
    // },
    // {
    //   name: "Permissions",
    //   icon: "courses-icn.svg",
    //   url: "/permissions",
    //   menuKey: "permissions",
    //   roleKey: "per",
    // },
  ],
  dashboard: [
    {
      name: "Agent",
      icon: "",
      url: "",
      key: "agent",
    },
    {
      name: "Student",
      icon: "",
      url: "",
      key: "student",
    },
    {
      name: "Course",
      icon: "",
      url: "",
      key: "course",
    },
    {
      name: "University",
      icon: "",
      url: "",
      key: "university",
    },
    {
      name: "Subject",
      icon: "",
      url: "",
      key: "subjects",
    },

    {
      name: "Promo Code",
      icon: "",
      url: "",
      key: "promoCode",
    },
    {
      name: "Application",
      icon: "",
      url: "",
      key: "application",
    },
    {
      name: "User",
      icon: "",
      url: "",
      key: "user",
    },
  ],
  permission: {
    label: {
      c: "Create",
      u: "Update",
      r: "Read",
      d: "Delete",
      ho: "Home",
      ag: "Agent",
      st: "Student",
      me: "Member",
      ca: "Cashback",
      cq: "Cashback Request",
      ap: "Applications",
      su: "Subjects",
      un: "University",
      co: "Course",
      pc: "Promo Code",
      us: "Users",
      // eq: "Enquiry",
      // ctus: "Contact Us",
      // cslg: "Counselling",
      cstn: "Consultation",
      se: "Setup",
      bl: "Blog",
      bc: "Blog Category",
      ca: "Career",
      jc: "Job Category",
      js: "Job Skills",
      pe: "Permissions",
    },
    list: [
      {
        key: "ho",
        types: ["r"],
      },
      {
        key: "ag",
        types: ["r", "c", "u"],
      },
      {
        key: "st",
        types: ["r", "c", "u"],
      },
      {
        key: "me",
        types: ["r", "c", "u"],
      },
      {
        key: "ca",
        types: ["r", "u"],
      },
      {
        key: "cq",
        types: ["r", "u"],
      },
      {
        key: "ap",
        types: ["r", "u"],
      },
      {
        key: "su",
        types: ["c", "u", "r"],
      },
      {
        key: "un",
        types: ["c", "u", "r", "d"],
      },
      {
        key: "co",
        types: ["c", "u", "r", "d"],
      },
      // {
      //   key: "eq",
      //   types: ["r"],
      // },
      // {
      //   key: "ctus",
      //   types: ["r"],
      // },
      // {
      //   key: "cslg",
      //   types: ["r"],
      // },
      {
        key: "cstn",
        types: ["r"],
      },
      {
        key: "pc",
        types: ["c", "u", "r"],
      },
      {
        key: "us",
        types: ["c", "u", "r"],
      },
      {
        key: "se",
        types: ["u", "r"],
      },
      {
        key: "bl",
        types: ["c", "u", "r", "d"],
      },
      {
        key: "bc",
        types: ["c", "u", "r", "d"],
      },
      {
        key: "ca",
        types: ["c", "u", "r", "d"],
      },
      {
        key: "jc",
        types: ["c", "u", "r", "d"],
      },
      {
        key: "js",
        types: ["c", "u", "r", "d"],
      },
      {
        key: "pe",
        types: ["c", "u", "r", "d"],
      },
    ],
  },

  studentView: [
    {
      key: "personal",
      title: "Personal",
      header: "General Information",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your personal details",
        b: "Name, DOB, Gender, Nationality, Language,..etc",
        c: "",
      },
      multi: false,
      step: 1,
    },
    {
      key: "address",
      title: "Address Details",
      header: "",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your address details",
        b: "Address, City, Country, Province, Postcode,…etc",
        c: "",
      },
      multi: false,
      step: 0,
    },
    {
      key: "education",
      title: "General",
      header: "Educational  Information",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your education details",
        b: "Country of education, Highest level of education,…etc",
        c: "",
      },
      multi: false,
      step: 2,
    },
    {
      key: "college",
      title: "College",
      header: "",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your college details",
        b: "Country of Institution, Level of education, Graduation date, Degree,…etc",
        c: "College details not added yet",
      },
      multi: true,
      step: 0,
    },
    // {
    //   key: "exam",
    //   title: "Course",
    //   header: "Test Scores",
    //   headerLink: true,
    //   subHeader: false,
    //   description: {
    //     a: "Please enter your test scores details",
    //     b: "Test name, Score, Date, Document,…etc",
    //     c: "Test score details not added yet",
    //   },
    //   multi: true,
    //   step: 3,
    // },
    {
      key: "background",
      title: "Background Informations",
      header: "Background Informations",
      headerLink: true,
      subHeader: false,
      description: {
        a: "Please enter your background details",
        b: "About Student Finance England ",
        c: "Background details not added yet",
      },
      step: 3,
    },
    {
      key: "workExp",
      title: "Company",
      header: "Work History",
      headerLink: true,
      subHeader: false,
      description: {
        a: "Please enter your work details ",
        b: "Company, Designation, Joining, end date and,…etc",
        c: "Company details not added yet",
      },
      multi: true,
      step: 4,
    },
  ],

  applicationSelect: [
    { value: "payment_pending:", label: "Pending Payment" },
    { value: "review:", label: "Under Review" },
    { value: "review_missing_info", label: "Missing Info" },
    { value: "submitted_to_university", label: "Submitted To University" },
    { value: "conditional_offer", label: "Offer" },
    { value: "unconditional_offer", label: "Unconditional Offer" },
    { value: "accepted", label: "Accepted" },
    { value: "sfe_process", label: "SFE Processing" },
    { value: "enrolled", label: "Enrolled" },
    { value: "rejected", label: "Rejected" },
    { value: "cancelled", label: "Cancelled" },
  ],

  applicationStatus: {
    payment_pending: "Pending Payment",
    fees_payment: "Fees Paid",
    review: "Under Review",
    review_missing_info: "Under Review",
    submitted_to_university: "Submitted To University",
    conditional_offer: "Offer",
    conditional_offer_letter: "Offer",
    conditional_offer_missing_info: "Offer",
    conditional_offer_interview: "Offer",
    conditional_offer_mock: "Offer",
    unconditional_offer: "Offer",
    accepted: "Accepted",
    sfe_process: "SFE Process",
    enrolled: "Enrolled",
    rejected: "Rejected",
    cancelled: "Cancelled",
  },

  filterFields: {
    agentSingle: {
      type: "object",
      value: "",
      finder: "",
    },
    agentMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    promotorMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    agentNameMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    studentSingle: {
      type: "object",
      value: "",
      finder: "",
    },
    studentMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    subAgentMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    studentNameMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    courseSingle: {
      type: "object",
      value: "",
      finder: "",
    },
    courseMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    universitySingle: {
      type: "object",
      value: "",
      finder: "",
    },
    universityMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    planMulti: {
      type: "object",
      value: "",
      finder: "",
    },
    country: {
      type: "object",
      value: "",
      finder: "",
    },
    province: {
      type: "object",
      value: "",
      finder: "",
    },
    city: {
      type: "object",
      value: "",
      finder: "",
    },
    applicationSelect: {
      type: "object",
      value: "",
      finder: "applicationSelect",
    },
    approved: { type: "boolObject", value: "", finder: "approved" },
    published: { type: "boolObject", value: "", finder: "published" },
    status: { type: "boolObject", value: "", finder: "status" },
    register: { type: "boolObject", value: "", finder: "register" },
    verified: { type: "boolObject", value: "", finder: "verified" },
    promotor: { type: "boolObject", value: "", finder: "promotor" },
    paymentStatus: { type: "boolObject", value: "", finder: "paymentStatus" },
    paymentProcess: { type: "boolObject", value: "", finder: "paymentProcess" },
    registerFrom: { type: "array", value: "", finder: "registerFrom" },
    type: { type: "object", value: "", finder: "type" },
    feesSlider: { type: "twoArray", value: [1, 10000000] },
    profileSlider: { type: "twoArray", value: [0, 100] },
    dateA: { type: "date", value: "", finder: "" },
    dateB: { type: "date", value: "", finder: "" },
    dateC: { type: "date", value: "", finder: "" },
    dateD: { type: "date", value: "", finder: "" },
  },
  object: {
    gradingSystem: {
      Percentage: "Percentage",
      Points: "Points",
      elGpa: "GPA",
      elCgpa: "CGPA",
      Letter: "Letter",
    },
  },
  agentProfile: [
    {
      type: "personal_address",
    },
    {
      type: "education_college",
    },
    {
      type: "background",
    },
    // {
    //   type: "exam",
    // },
    {
      type: "workExp",
    },
    {
      type: "document",
    },
  ],
  educationLevelProfile: [
    { label: "Entry Level Certificate", value: "Entry Level Certificate" },
    {
      label: "NQF/ QCF L1 - GCSE Grade D-G/ BTEC Certificate or Diploma",
      value: "NQF/ QCF L1 - GCSE Grade D-G/ BTEC Certificate or Diploma",
    },
    {
      label: "NQF/ QCF L2 - GCSE Grade A-C, BTEC Certificate or Diploma",
      value: "NQF/ QCF L2 - GCSE Grade A-C, BTEC Certificate or Diploma",
    },
    {
      label:
        "NQF/ QCF L3 - AS/A Levels, BTEC Certificate or Diploma, BTEC and OCR Nationals",
      value:
        "NQF/ QCF L3 - AS/A Levels, BTEC Certificate or Diploma, BTEC and OCR Nationals",
    },
    {
      label:
        "NQF/ QCF L4 - BTEC Professional Diplomas, certificates and awards (HNC) certificates of Higher Education",
      value:
        "NQF/ QCF L4 - BTEC Professional Diplomas, certificates and awards (HNC) certificates of Higher Education",
    },
    {
      label:
        "NQF/ QCF L5 - HNDs/ Diploma of Higher and Further Education, Foundation Degree",
      value:
        "NQF/ QCF L5 - HNDs/ Diploma of Higher and Further Education, Foundation Degree",
    },
    {
      label:
        "NQF/ QCF L6 - BTEC Advanced Diplomas, Bachelor Degrees and Graduate Certificates",
      value:
        "NQF/ QCF L6 - BTEC Advanced Diplomas, Bachelor Degrees and Graduate Certificates",
    },
    {
      label:
        "NQF/ QCF L7 - Postgraduate Certificates and Diplomas, Masters Degree",
      value:
        "NQF/ QCF L7 - Postgraduate Certificates and Diplomas, Masters Degree",
    },
    { label: "Other Qualifications", value: "Other Qualifications" },
    { label: "Work Experience Only", value: "Work Experience Only" },
  ],
  financeYearTakenBefore: [
    { label: "1 Year", value: "1 Year" },
    { label: "2 Year", value: "2 Year" },
    { label: "3 Year", value: "3 Year" },
    { label: "4 Year", value: "4 Year" },
  ],
  visaStatus: [
    { label: "British National", value: "British National" },
    { label: "EU National", value: "EU National" },
    {
      label: "Indefinite Leave to Remain (ILR)",
      value: "Indefinite Leave to Remain (ILR)",
    },
    {
      label: "Limited Leave to Remain (LLR)",
      value: "Limited Leave to Remain (LLR)",
    },
    {
      label: "Family Member of a British National",
      value: "Family Member of a British National",
    },
    {
      label: "Family Member of an EU/ EAA National",
      value: "Family Member of an EU/ EAA National",
    },
    { label: "Refugee/ Asylum Seeker", value: "Refugee/ Asylum Seeker" },
  ],
  livingPeriod: [
    { label: "Less than a  Year", value: "Less than a  Year" },
    { label: "1 - 2 Years", value: "1 - 2 Years" },
    { label: "2 - 3 Years", value: "2 - 3 Years" },
    { label: "3 - 4 Years", value: "3 - 4 Years" },
    { label: "4 - 5 Years", value: "4 - 5 Years" },
    { label: "5 - 10 Years", value: "5 - 10 Years" },
    { label: "10 - 15 Years", value: "10 - 15 Years" },
    { label: "15 - 20 Years", value: "15 - 20 Years" },
    { label: "20 - 25 Years", value: "20 - 25 Years" },
    { label: "More than 30 Years", value: "More than 30 Years" },
  ],
  yearOfExp: [
    { label: "Less than 2 Years", value: "Less than 2 Years" },
    { label: "Less than 5 Years", value: "Less than 5 Years" },
    { label: "More than 5 Years", value: "More than 5 Years" },
  ],
  studentArrayFields: [
    "college",
    "exam",
    "workExp",
    "extraCurricular",
    "otherActivity",
  ],
  gradingSystem: [
    { label: "Percentage", value: "Percentage" },
    { label: "Points", value: "Points" },
    { label: "GPA", value: "elGpa" },
    { label: "CGPA", value: "elCgpa" },
    { label: "Letter", value: "Letter" },
  ],
};
