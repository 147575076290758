import Switch from "@mui/material/Switch";
import { Fragment, useEffect, useState } from "react";
// includes
import StatusBar from "elements/statusBar";
import { api, common, types } from "helpers";
import CommonStudentDocs from "./studentDocs";
// files
import studentFields from "assets/files/studentFields.json";

function StudentView(props) {
  // state
  const [id] = useState(props.id);
  const [formType] = useState(props.formType);
  const [formModule] = useState(props.formModule);
  const [formModuleList] = useState(String(props.formModule).split("_"));
  const [filled, setFilled] = useState({});
  const [status, setStatus] = useState("process");
  const [loading, setLoading] = useState(null);
  const [result, setResult] = useState(null);
  const PersonalDoLabel = {
    passportDocs: "Passport Document",
    birthDocs: "Birth Certificate or Proof of ID Document",
    euDocs: "EU Share Code Document",
    brpDocs: "BRP Document",
  };
  const EuList = [
    "AD",
    "AL",
    "AT",
    "AX",
    "BA",
    "BE",
    "BG",
    "BY",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GG",
    "GR",
    "HR",
    "HU",
    "IE",
    "IM",
    "IC",
    "IT",
    "JE",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "MD",
    "MK",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SE",
    "SI",
    "SJ",
    "SK",
    "SM",
    "UA",
    "VA",
  ];
  const [permission] = useState(common.getUserPermission());

  const ConditionalLabel = (key, value, result) => {
    if (["passportDocs", "euDocs", "brpDocs"].includes(key)) {
      if (key === "passportDocs" && result["birth"]?._id === "GB") {
        return PersonalDoLabel["birthDocs"];
      }
      return PersonalDoLabel[key];
    }
    return value;
  };

  const convertFieldValue = (key, value, result) => {
    if (
      ["isFinanceTakenBefore", "refugee", "publicCare", "disability"].includes(
        key
      )
    ) {
      return value
        .replace("Year", "year")
        .replace("Y", "Yes")
        .replace("N", "No");
    }
    if (["yearOfExp"].includes(key)) return `${result[key]}`;

    return value;
  };
  const hideAndShowView = (rsData) => {
    let sub = {
      personal: "personal",
      education: "education",
      background: "background",
    };
    //In United Kingdom
    if (rsData?.birth?._id == "GB") {
      ["passportNumber", "brpDocs", "euDocs"].map(
        (item) => delete studentFields[sub?.personal][item]
      );
    }

    //In European
    if (!EuList.includes(rsData?.birth?._id)) {
      ["euDocs"].map((item) => delete studentFields[sub?.personal][item]);
    }
    if (
      !["Work Experience Only", "Other Qualifications"].includes(
        rsData?.highLevelOfEducation
      )
    ) {
      [
        "yearOfExp",
        "ruWorking",
        "expDocs",
        "otherQualification",
        "otherQualificationDesc",
        "otherQualificationDocs",
      ].map((item) => delete studentFields[sub?.education][item]);
      studentFields["education"]["countryOfEducation"] = {
        isReq: "Select the country",
        view: ["Country of Education", 4, true, "select"],
      };
      studentFields["education"]["grade"] = {
        isReq: "Credits is required",
        view: ["Credits", 4, true, "string"],
      };
    } else {
      ["countryOfEducation", "grade"].map(
        (item) => delete studentFields[sub?.education][item]
      );
    }
    if (rsData?.highLevelOfEducation === "Work Experience Only") {
      [
        "otherQualification",
        "otherQualificationDesc",
        "otherQualificationDocs",
      ].map((item) => delete studentFields[sub?.education][item]);
      studentFields["education"]["yearOfExp"] = {
        isReq: "",
        view: ["Year of Working", 4, true, "string"],
      };
      studentFields["education"]["ruWorking"] = {
        isReq: "",
        view: ["Are you currently working", 4, true, "string"],
      };
      studentFields["education"]["expDocs"] = {
        isReq: "",
        view: ["Work Experience Document", 4, true, "array"],
      };
    }
    if (rsData?.highLevelOfEducation === "Other Qualifications") {
      ["yearOfExp", "ruWorking", "expDocs"].map(
        (item) => delete studentFields[sub?.education][item]
      );
      studentFields["education"]["otherQualification"] = {
        isReq: "",
        view: ["Qualification", 4, true, "string"],
      };
      studentFields["education"]["otherQualificationDesc"] = {
        isReq: "",
        view: ["Description", 4, true, "string"],
      };
      studentFields["education"]["otherQualificationDocs"] = {
        isReq: "",
        view: ["Qualification Document", 4, true, "array"],
      };
    }

    if (rsData?.isFinanceTakenBefore === "N") {
      ["financeYearTakenBefore"].map(
        (item) => delete studentFields[sub?.background][item]
      );
    }
  };

  // effect
  useEffect(() => {
    initData();
  }, []);

  // api
  const initData = () => {
    if (formType === "student") {
      studentInfo();
    } else if (formType === "application") {
      applicationInfo();
    }
  };

  const studentInfo = () => {
    let data = {
      url: "student",
      method: "GET",
      query: `/${id}`,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        checkFilled(res.data);
        setResult(res.data);
        hideAndShowView(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const applicationInfo = () => {
    let data = {
      url: "application",
      method: "GET",
      query: `/${id}`,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        checkFilled(res.data.profile);
        setResult(res.data.profile);
        hideAndShowView(res.data.profile);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const checkFilled = (res) => {
    let isFilled = {};
    Object.entries(studentFields).map(([i, k]) => {
      let fields = studentFields[i];
      let isAllow = false;
      if (["college", "exam", "workExp"].includes(i)) {
        if (res[i].length > 0) {
          isAllow = true;
        }
      } else {
        Object.entries(fields).map(([m, n]) => {
          if (res[m] && n.view?.[2]) {
            isAllow = true;
          }
        });
      }
      isFilled[i] = isAllow;
    });
    setFilled(isFilled);
  };

  const statusUpdate = (e) => {
    setLoading("status");
    let data = {
      url: "student",
      method: "PUT",
      query: `/${id}`,
      body: {
        [e.target.name]: e.target.checked,
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Record has been updated");
      } else {
        common.notify("E", "Unable to update data please try again");
      }
      setLoading(null);
    });
  };

  // render
  return (
    <div className="inner-content-wrap">
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          {formType === "student" && (
            <div className="et-view-content-wrap">
              <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                <h3 className="et-view-secondary-title">Status</h3>
              </div>
              <ul className="et-vc-list row">
                <li className="col-md-4 et-view-border-bottom et-view-border-right">
                  <div className="et-list-inner">
                    <p className="et-field-name">Active</p>
                    <p className="et-field-value">
                      <Switch
                        defaultChecked={result.isActive}
                        name="isActive"
                        color="secondary"
                        onChange={(e) => statusUpdate(e)}
                        disabled={permission.includes("stu") ? false : true}
                      />
                    </p>
                  </div>
                </li>
                {result?.id_s && result?.registeredFrom == "Agent" && (
                  <li className="col-md-4 et-view-border-bottom et-view-border-right">
                    <div className="et-list-inner">
                      <p className="et-field-name">Login Invited</p>
                      Yes
                    </div>
                  </li>
                )}
              </ul>
            </div>
          )}
          {types.studentView.map((i, k) => {
            if (
              formModuleList.includes(i.key) ||
              ["all"].includes(formModule)
            ) {
              return (
                <Fragment key={k}>
                  <div className="et-view-content-wrap">
                    {/* Main Header */}
                    {i.header && (
                      <div className="et-view-title-wrap d-flex justify-content-between align-items-center bg-blue">
                        <h1 className="et-view-primary-title">{i.header}</h1>
                      </div>
                    )}

                    {/* Sub Header */}
                    {i.subHeader && (
                      <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                        <h3 className="et-view-secondary-title">{i.title}</h3>
                      </div>
                    )}

                    {/* Inner Content */}
                    {!filled[i.key] ? (
                      <div className="et-vc--no-info d-flex justify-content-between align-items-center et-view-border-bottom">
                        <div className="et-vc-left">
                          <p className="et-vc-label">{i.description.c}</p>
                          <p className="et-vc-info-required">
                            {i.description.b}
                          </p>
                        </div>
                      </div>
                    ) : i.multi ? (
                      result[i.key].map((cItem, cKey) => (
                        <Fragment key={cKey}>
                          <div className="et-vc-step-info d-flex align-items-center justify-content-between et-view-border-bottom">
                            <div>
                              <span className="et-vc-step me-3">
                                0{Number(cKey) + 1}
                              </span>
                              <span className="et-vc-step-title">
                                {i.title}
                              </span>
                            </div>
                          </div>

                          <div className="et-vc-info et-view-border-bottom">
                            <ul className="et-vc-list row m-0">
                              {Object.keys(studentFields[i.key]).map(
                                (j, k) =>
                                  studentFields[i.key][j].view !== "" && (
                                    <li
                                      className={`col-md-${
                                        studentFields[i.key][j].view[1]
                                      } et-view-border-bottom et-view-border-right`}
                                      key={k}
                                    >
                                      <div className="et-list-inner">
                                        <p className="et-field-name">
                                          {studentFields[i.key][j].view[0]}
                                        </p>
                                        {j === "docs" ? (
                                          <Fragment>
                                            {cItem[j].length
                                              ? cItem[j].map((dItem, dKey) => (
                                                  <Fragment>
                                                    <span
                                                      className="et-field-value et-link"
                                                      key={dKey}
                                                      onClick={() =>
                                                        common.openCloudUrl(
                                                          dItem
                                                        )
                                                      }
                                                    >
                                                      {common.fileName(dItem)}
                                                    </span>
                                                    <br />
                                                  </Fragment>
                                                ))
                                              : "-"}
                                          </Fragment>
                                        ) : j === "testScore" ? (
                                          <p className="et-field-value">
                                            L:{cItem["listening"] ?? 0} / R:
                                            {cItem["reading"] ?? 0} / W:
                                            {cItem["writing"] ?? 0} / S:
                                            {cItem["speaking"] ?? 0}
                                          </p>
                                        ) : (
                                          <p className="et-field-value">
                                            {["gradingSystem"].includes(j)
                                              ? types.object[j][
                                                  (studentFields[i.key][j]
                                                    .view[3],
                                                  cItem[j])
                                                ]
                                              : common.readable(
                                                  studentFields[i.key][j]
                                                    .view[3],
                                                  cItem[j]
                                                )}
                                          </p>
                                        )}
                                      </div>
                                    </li>
                                  )
                              )}
                            </ul>
                          </div>
                        </Fragment>
                      ))
                    ) : (
                      <div className="et-vc-info et-view-border-bottom">
                        <ul className="et-vc-list row">
                          {Object.keys(studentFields[i.key]).map(
                            (j, k) =>
                              studentFields[i.key][j].view != "" && (
                                <li
                                  className={`col-md-${
                                    studentFields[i.key][j].view[1]
                                  } et-view-border-bottom et-view-border-right`}
                                  key={k}
                                >
                                  <div className="et-list-inner">
                                    <p className="et-field-name">
                                      {ConditionalLabel(
                                        j,
                                        studentFields[i.key][j].view[0],
                                        result
                                      )}
                                    </p>
                                    {j === "docs" ? (
                                      <Fragment>
                                        {result[j]?.length
                                          ? result[j]?.map((dItem, dKey) => (
                                              <p
                                                className="et-field-value"
                                                key={dKey}
                                              >
                                                {common.fileName(dItem)}
                                              </p>
                                            ))
                                          : "-"}
                                      </Fragment>
                                    ) : [
                                        "passportDocs",
                                        "otherQualificationDocs",
                                        "expDocs",
                                      ].includes(j) ? (
                                      <Fragment>
                                        {result[j]?.length
                                          ? result[j]?.map((dItem, dKey) => (
                                              <p
                                                className="et-field-value et-link res-pad"
                                                onClick={() =>
                                                  common.openCloudUrl(dItem)
                                                }
                                              >
                                                {common.fileName(dItem)}
                                              </p>
                                            ))
                                          : "-"}
                                      </Fragment>
                                    ) : [
                                        //"passportDocs",
                                        "euDocs",
                                        "brpDocs",
                                      ].includes(j) ? (
                                      <p className="et-field-value">
                                        {result[j].length ? (
                                          <p
                                            className="et-field-value et-link res-pad"
                                            onClick={() =>
                                              common.openCloudUrl(result[j])
                                            }
                                          >
                                            {common.fileName(result[j])}
                                          </p>
                                        ) : (
                                          "-"
                                        )}
                                      </p>
                                    ) : (
                                      <p className="et-field-value">
                                        {studentFields[i.key][j].view[3] ===
                                        "email" ? (
                                          <a
                                            href={"mailto:" + result[j]}
                                            target="_blank"
                                            className="et-field-value et-link res-pad txt-dec-none"
                                          >
                                            {common.hideEmail(result[j])}
                                          </a>
                                        ) : studentFields[i.key][j].view[3] ===
                                          "mobile" ? (
                                          <a
                                            href={"tel:" + result.mobile}
                                            className="et-field-value et-link res-pad txt-dec-none"
                                          >
                                            {common.hideMobile(result.mobile)}
                                          </a>
                                        ) : (
                                          <span>
                                            {convertFieldValue(
                                              j,
                                              common.readable(
                                                studentFields[i.key][j].view[3],
                                                result[j]
                                              ),
                                              result
                                            )}
                                          </span>
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </li>
                              )
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            }
          })}
          {["document", "all"].includes(formModule) && (
            <CommonStudentDocs data={result} />
          )}
        </Fragment>
      )}
    </div>
  );
}
export default StudentView;
