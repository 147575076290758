import React, { useRef } from "react";
// import { Editor } from "@tinymce/tinymce-react";
import { Editor } from "react-draft-wysiwyg";
import AmlHashForm from "aml-hash-form";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
// includes
import FieldSelect from "elements/fieldSelect";
import StatusBar from "elements/statusBar";
import { api, common, config, types } from "helpers";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import SkillList from "./skill/list";

function Form() {
  const editorRef = useRef(null);
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState(id ? "process" : "success");
  const [loading, setLoading] = useState(null);
  const [descErr, setDescErr] = useState("");
  const [error, setError] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [skillList, setSkillList] = useState([]);

  const jobType = [
    { value: "Full-time", label: "Full-time" },
    { value: "Part-time", label: "Part-time" },
  ];

  // validate
  const formFields = {
    title: { validate: ["req"] },

    type: { validate: ["objValReq#Please select the type"], obj: "value" },
    skills: {
      validate: ["objValReq#Please select the skills"],
      obj: "value",
    },
    categoryId: {
      validate: ["objValReq#Please select the category"],
      obj: "value",
    },

    description: { value: EditorState.createEmpty() },
    countryId: {
      validate: ["objValReq#Please select the country"],
      obj: "value",
    },
    url: { validate: ["req"] },
    isActive: {
      validate: ["objValReq#Please select status"],
      obj: "value",
    },
  };
  const {
    fields,
    errors,
    setValue,
    handleChange,
    handleSubmit,
    setMultiValue,
  } = AmlHashForm(formFields);

  useEffect(() => {
    getCategory();
    getSkills();
    getCareer();
    if (id) {
      getInfo();
      getSkills();
    }
  }, []);

  const getCareer = () => {
    let data = {
      url: "careerContent",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res?.data?.data;
      }
    });
  };

  const getCategory = () => {
    let data = {
      url: "jobCategory",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res?.data?.data;
        let list1 = [];
        result?.length > 0 &&
          result?.map((item) => {
            let val = { label: item.name, value: item._id };
            list1.push(val);
          });
        setCategoryList(list1);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const getSkills = () => {
    let data = {
      url: "jobSkill",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res?.data?.data;
        let list2 = [];
        result?.length > 0 &&
          result?.map((item) => {
            let val = { label: item.name, value: item.name };
            list2.push(val);
          });
        setSkillList(list2);

        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const getInfo = () => {
    let data = {
      url: "careerContent",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;

        let status = types.status.filter((item) => {
          return item.value === result.isActive;
        });

        setMultiValue({
          title: result.title,
          type: result.type
            ? {
                label:
                  result?.type?.charAt(0).toUpperCase() + result?.type.slice(1),
                value: result.type,
              }
            : null,
          skills: result?.skills?.map((item) => ({
            label: item,
            value: item,
          })),

          description: setHtml(result.description),
          countryId: result.countryId
            ? {
                label: result.countryId,
                value: result.countryId,
              }
            : null,
          categoryId: result.categoryId
            ? {
                label: result.categoryId.name,
                value: result.categoryId._id,
              }
            : null,
          isActive: status[0],
          url: result?.url,
        });
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const getHtml = (val) => {
    return fields[val]?.getCurrentContent
      ? draftToHtml(convertToRaw(fields[val].getCurrentContent()))
      : "";
  };
  const setHtml = (val) => {
    return EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(val))
    );
  };

  const getLength = (val) => {
    return common.htmlToString(getHtml(val)).length;
  };
  const careerSubmit = () => {
    let isValid = handleSubmit();

    if (getLength("description") == 1) {
      setDescErr("Please Write Description");
    } else {
      setDescErr("");
    }

    if (isValid && descErr == "") {
      onSubmit();
    }
  };
  const onSubmit = () => {
    setLoading(true);
    let skills = fields?.skills.map((opt) => {
      return opt.value;
    });

    let data = {};
    let params = {
      title: fields.title,
      type: fields.type ? fields.type.value : "",
      skills: skills,
      description: getHtml("description"),
      categoryId: fields.categoryId ? fields.categoryId.value : "",
      countryId: fields.countryId ? fields.countryId.value : "",
      isActive: fields.isActive ? fields.isActive.value : "",
      url: fields.url,
    };
    if (id) {
      data = {
        url: "careerContent",
        method: "PUT",
        query: `/${id}`,
        body: { ...params },
      };
    } else {
      data = {
        url: "careerContent",
        method: "POST",
        body: { ...params },
      };
    }
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify(
          "S",
          `Career Details  ${!id ? "created" : "updated"} successfully`
        );
        navigate("/career");
      } else if (res.data.error) {
        common.notify("E", res.data.error);
      } else {
        common.notify("E", "Something went wrong");
      }
      setLoading(false);
    });
  };
  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={common.loadImg("back-icn.svg")}
            className="ed-cp"
            alt=""
            onClick={() => navigate("/career")}
          />
          <h1 className="page-title ps-4">{id ? "Edit" : "Add New"} Job</h1>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <div className="container-fluid">
            <h2 className="form-title">Career</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label>Job Title</label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Job Title"
                    className="form-control"
                    value={fields.title}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.title}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Country</label>
                  {/* <Select
                    className="custom-select-box"
                    value={fields.nationality}
                    options={types.country}
                    isClearable={true}
                    // onChange={(data) => {
                    //   setValue("nationality", data);
                    // }}
                  />
                  <p className="error-txt">{errors.category}</p> */}
                  <FieldSelect
                    api="common_country"
                    name="countryId"
                    query="?all=show"
                    get="name"
                    set="code"
                    value={fields.countryId}
                    placeholder="Please select the country"
                    // render={selectRender.country}
                    clearable={true}
                    finder={false}
                    multi={false}
                    onSelect={(data) => {
                      setMultiValue({
                        countryId: data,
                      });
                    }}
                  />
                  <p className="error-txt">{errors.countryId}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Select Category</label>
                  <Select
                    name="categoryId"
                    className="custom-select-box"
                    value={fields.categoryId}
                    options={categoryList}
                    isClearable={false}
                    // isMulti={true}
                    onChange={(data) => {
                      setMultiValue({ categoryId: data });
                    }}
                  />
                  <p className="error-txt">{errors.categoryId}</p>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Select Skills</label>
                  <Select
                    name="skills"
                    className="custom-select-box"
                    value={fields.skills}
                    options={skillList}
                    isClearable={false}
                    isMulti={true}
                    onChange={(data) => {
                      setMultiValue({ skills: data });
                    }}
                  />
                  <p className="error-txt">{errors.skills}</p>
                </div>

                <div className="col-md-6 mb-4">
                  <label>Job Type</label>
                  <Select
                    name="type"
                    className="custom-select-box"
                    value={fields.type}
                    options={jobType}
                    isClearable={false}
                    onChange={(data) => {
                      setMultiValue({ type: data });
                    }}
                  />
                  <p className="error-txt">{errors.type}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Job Link</label>
                  <input
                    name="url"
                    type="text"
                    placeholder="Linked in url"
                    className="form-control"
                    value={fields.url}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.url}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Status</label>
                  <Select
                    name="isActive"
                    className="custom-select-box"
                    value={fields.isActive}
                    options={types.status}
                    isClearable={false}
                    onChange={(data) => {
                      setMultiValue({ isActive: data });
                    }}
                  />
                  <p className="error-txt">{errors.isActive}</p>
                </div>
                <div className="col-md-12 mb-4">
                  <label>Description</label>
                  <Editor
                    editorState={fields.description}
                    onEditorStateChange={(e) =>
                      setMultiValue({ description: e })
                    }
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    toolbar={{
                      options: ["blockType", "list"],
                      blockType: {
                        inDropdown: true,
                        options: ["H1", "H2", "H3", "H4", "H5", "H6"],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                      },
                      list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ["unordered", "ordered"],
                      },
                    }}
                  />
                  <p className="error-txt">
                    {getLength("description") > 10 ? "" : descErr}
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn ed-btn-default ed-btn-save-dt"
                onClick={careerSubmit}
                disabled={loading}
              >
                {loading ? "Processing..." : "Save & Update"}
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default Form;
