import { common } from "helpers";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import { api } from "helpers";

function ConsultationList() {
  // init
  const navigate = useNavigate();
  const [reload, setReload] = useState(new Date());
  const [deleteData, setDeleteData] = useState(null);
  const [loading, setLoading] = useState(false);
  // state
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    if (!permission.includes("eqr")) {
      navigate("/profile");
    }
  }, []);
  // functions
  const callAction = (data) => {
    if (data.type == "Export" || data.type == "export") {
      exportRecord(data);
    } else if (data.type == "View") {
      // if (data.item.type == "counselling") {
      //   navigate("/consultation/counselling_view/" + data.item._id);
      // } else if (data.item.type == "enquiry") {
      //   navigate("/consultation/enquiry_view/" + data.item._id);
      // } else if (data.item.type == "contact_us") {
      //   navigate("/consultation/contact_us_view/" + data.item._id);
      // } else {
      //   // talk_to_expert
      //   navigate("/consultation/talk_to_expert_view/" + data.item._id);
      // }
      navigate(`/consultation/${data.item.type }/${data.item._id}`)

    } else if (data.type == "massdelete") {
      DeleteRecord(data.item);
    }
  };

  const DeleteRecord = (item) => {
    let data = {
      url: "consultation",
      query: "/delete",
      method: "PUT",
      body: { ids: item },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Records has been removed");
        setReload(new Date());
      }
    });
  };

  //api
  const exportRecord = (val) => {
    let data = {
      url: "consultation_export",
      method: "GET",
      query: val.query,
      responseType: "file",
      fileName: "consultation",
    };
    api.call(data, (res) => {});
  };

  return (
    permission.includes("eqr") && (
      <>
        <AgTable
          module="adminConsultation"
          reload={reload}
          filterData={{
            type: { key: "type", value: "", title: "Consultation Type" },
            dateA: { key: "startDate", value: "", title: "Created Date From" },
            dateB: { key: "endDate", value: "", title: "Created Date To" },
          }}
          actionPermission={common.accessTable(permission, [
            "cstnr",
            "cstnc",
            "cstnu",
            "cstnd",
          ])}
          onAction={(data) => callAction(data)}
        />
      </>
    )
  );
}

export default ConsultationList;
