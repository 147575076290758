import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// includes
import { api, common } from "helpers";
import StatusBar from "elements/statusBar";

function View() {
  const { type, id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState(null);

  // effect
  useEffect(() => {
    getInfo();
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "consultation",
      method: "GET",
      query: `/${id}`,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setResult(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        <h1 className="page-title ps-4 text-transform">{type} View</h1>
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <div className="et-view-content-wrap">
                {/* Inner Content */}
                <div className="et-vc-info et-view-border-bottom">
                  {/* <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">
                      Personal Information
                    </h3>
                  </div> */}
                  <ul className="et-vc-list row">
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Name</p>
                        <p className="et-field-value">
                          {result?.first_name ? result?.first_name : "-"}{" "}
                          {result?.last_name ? result?.last_name : ""}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Email</p>
                        <p className="et-field-value">
                          {result?.email ? result?.email : "-"}
                        </p>
                      </div>
                    </li>
                    <li className="col-md-4 et-view-border-bottom et-view-border-right">
                      <div className="et-list-inner">
                        <p className="et-field-name">Mobile</p>
                        <p className="et-field-value">
                          {result?.mobile ? result?.mobile : "-"}
                        </p>
                      </div>
                    </li>
                    {result?.degree && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">Degree</p>
                          <p className="et-field-value">
                            {result?.degree ? result?.degree : "-"}
                          </p>
                        </div>
                      </li>
                    )}
                    {result?.country && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">Country</p>
                          <p className="et-field-value">
                            {result?.country ? result?.country : "-"}
                          </p>
                        </div>
                      </li>
                    )}

                    {result?.city && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">City</p>
                          <p className="et-field-value">
                            {result?.city ? result?.city : "-"}
                          </p>
                        </div>
                      </li>
                    )}
                    {result?.intake && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">InTake</p>
                          <p className="et-field-value">
                            {result?.intake ? result?.intake : "-"}
                          </p>
                        </div>
                      </li>
                    )}
                    {result?.appoinment_date && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">Appoinment Date</p>
                          <p className="et-field-value">
                            {result?.appoinment_date
                              ? result?.appoinment_date
                              : "-"}
                          </p>
                        </div>
                      </li>
                    )}
                    {result?.time && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">Time</p>
                          <p className="et-field-value">
                            {result?.time ? result?.time : "-"}
                          </p>
                        </div>
                      </li>
                    )}
                    {result?.course && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">Course</p>
                          <p className="et-field-value">
                            {result?.course ? result?.course : "-"}
                          </p>
                        </div>
                      </li>
                    )}
                    {result?.subject && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">Subject</p>
                          <p className="et-field-value">
                            {result?.subject ? result?.subject : "-"}
                          </p>
                        </div>
                      </li>
                    )}
                    {result?.message && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">Message</p>
                          <p className="et-field-value">
                            {result?.message ? result?.message : "-"}
                          </p>
                        </div>
                      </li>
                    )}
                    {result?.get_info_on_whatsapp && (
                      <li className="col-md-4 et-view-border-bottom et-view-border-right">
                        <div className="et-list-inner">
                          <p className="et-field-name">
                            Get Whatsup Notification Enabled?
                          </p>
                          <p className="et-field-value">
                            {result?.get_info_on_whatsapp ? "Yes" : "No"}
                          </p>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
}
export default View;
