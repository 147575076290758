import { AES, enc } from "crypto-js";
import moment from "moment";
import { toast } from "react-toastify";
// includes
import { config } from "./config";
import { types } from "./types";

export const common = {
  hideEmail(email) {
    return email.replace(/(.{2})(.*)(?=@)/, function (gp1, gp2, gp3) {
      for (let i = 0; i < gp3.length; i++) {
        gp2 += "*";
      }
      return gp2;
    });
  },

  hideMobile(phone) {
    if (phone.length > 5) {
      let prefixLength = 2;
      let suffixLength = 3;

      let prefix = phone.substring(0, prefixLength);
      let suffix = phone.slice(-suffixLength);
      let nbStars = phone.length - (prefixLength + suffixLength);

      let formattedPhone = prefix + "*".repeat(nbStars) + suffix;
      return formattedPhone;
    } else {
      return phone;
    }
  },

  loadImg(img) {
    let result = require("../assets/img/" + img);
    return result; //.default;
  },

  cloudImg(img) {
    let result = config.api.cloudImgPath + img;
    return result; //.default;
  },

  openCloudUrl(url) {
    window.open(config.api.cloudImgPath + url, "_blank");
  },

  openObjectUrl(obj) {
    window.open(URL.createObjectURL(obj), "_blank");
  },

  fileName(file) {
    let ext = file.substring(file.lastIndexOf("."));
    file = file.split("_").slice(0, -1).join("_") + ext;
    return file.substring(file.indexOf("/") + 1);
  },

  notify(type, msg) {
    switch (type) {
      case "S":
        toast.success(msg, config.elements.toast);
        break;
      case "W":
        toast.warning(msg, config.elements.toast);
        break;
      case "E":
        toast.error(msg, config.elements.toast);
        break;
      case "I":
        toast.info(msg, config.elements.toast);
        break;
      case "M":
        toast(msg, config.elements.toast);
        break;
    }
  },

  // local storage
  localSet(key, value) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },
  localGet(key, json = false) {
    let value = localStorage.getItem(key);
    if (json) {
      value = JSON.parse(value);
    }
    return value;
  },
  localRemove(key) {
    localStorage.removeItem(key);
  },

  // local data
  adminLocalDataSet(value) {
    this.localSet("adminLocalData", value);
  },

  adminLocalDataGet() {
    let res = this.localGet("adminLocalData", true);
    this.adminLocalDataRemove();
    return res;
  },

  adminLocalDataRemove() {
    return this.localRemove("adminLocalData");
  },

  // adminTempData
  adminTempDataSet(value) {
    this.localSet("adminTempData", common.crypt(value, true));
  },

  adminTempDataGet() {
    let data = this.localGet("adminTempData");
    if (data) {
      return this.deCrypt(data, true);
    }
    return null;
  },

  adminTempDataRemove() {
    return this.localRemove("adminTempData");
  },

  // auth
  checkAuth() {
    let adminAuthData = this.localGet("adminAuthData");
    if (adminAuthData == "" || adminAuthData == null) {
      return false;
    } else {
      return this.getAuth() ? true : false;
    }
  },

  getAuth() {
    let data = this.localGet("adminAuthData");
    return this.deCrypt(data, true);
  },

  // encode decode
  crypt(str, json = false) {
    if (json) {
      str = JSON.stringify(str);
    }
    return AES.encrypt(str, config.cryptKey).toString();
  },
  deCrypt(str, json = false) {
    try {
      let bytes = AES.decrypt(str, config.cryptKey);
      let data = bytes.toString(enc.Utf8);
      if (data == "") {
        localStorage.clear();
        this.redirect("login");
      } else {
        if (json) {
          return JSON.parse(data);
        } else {
          return data;
        }
      }
    } catch (err) {
      localStorage.clear();
      this.redirect("login");
    }
  },

  // validation
  isEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  apiStatus(res) {
    let status = "success";
    if (res.error) {
      status = "error";
    } else if (res.data?.length === 0 || res.data === null) {
      status = "empty";
    }
    return status;
  },

  getMonthList() {
    return [
      { label: "January", value: "1" },
      { label: "Febuary", value: "2" },
      { label: "March", value: "3" },
      { label: "April", value: "4" },
      { label: "May", value: "5" },
      { label: "June", value: "6" },
      { label: "July", value: "7" },
      { label: "August", value: "8" },
      { label: "September", value: "9" },
      { label: "October", value: "10" },
      { label: "November", value: "11" },
      { label: "December", value: "12" },
    ];
  },

  SetArrayToSelect(data) {
    return data.map((i) => ({ label: i, value: i, isDisabled: false }));
  },

  SetMultiSelect(data, label, value, join = "") {
    let optionsData = [];
    if (data && data.length) {
      data.map((obj) => {
        let options = {
          label: `${obj[label]}${
            join === "universityName"
              ? ` (${obj?.university ? obj.university?.name : "None"})`
              : ``
          }`,
          value: obj[value],
        };
        optionsData.push(options);
      });
    }
    return optionsData;
  },

  SetSelect(val) {
    return val ? { label: val, value: val } : null;
  },

  FindSelect(data, val) {
    let result = data.find((obj) => obj.value?.toString() === val?.toString());
    return result ?? null;
  },

  FindMultiSelect(data, val) {
    let result = data.filter(
      (obj) => val?.value?.toString() === obj?.value?.toString()
    );
    return result ?? null;
  },

  getFullYear(val) {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(val), (val, index) => index + year);
    return years;
  },

  paging(paging, type) {
    if (type == "offset") {
      return (paging.page - 1) * paging.limit;
    } else if (type == "serial") {
      return (paging.page - 1) * paging.limit;
    }
  },

  reParse(data) {
    return JSON.parse(JSON.stringify(data));
  },

  scrollToRef(myRef, adjust = 0) {
    window.scroll({
      top: myRef.current.offsetTop - adjust,
      behavior: "smooth",
    });
  },

  scrollTo(to = "") {
    if (to === "top") {
      window.scroll({ top: 0, behavior: "smooth" });
    } else if (to === "bottom") {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  },

  // validate
  validateType(val, type = "s") {
    let result = val;
    if (type == "n") {
      result = common.onlyNumbers(val);
    } else if (type == "f") {
      result = common.onlyFloat(val);
    }
    return result;
  },

  validateMobile(number) {
    return number.replace(/\D/g, "");
  },

  // input
  onlyNumbers(str) {
    return str.replace(/[^0-9]+/gi, "");
  },

  onlyFloat(str) {
    return str.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
  },

  modalStyle(width = 75) {
    return {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: `${width}%`,
        maxHeight: "800px",
        marginRight: "-50%",
        padding: "0",
        borderRadius: "0",
        border: "none",
        boxShadow: "0px 0px 20px rgba(77, 78, 141, 0.1773601)",
        transform: "translate(-50%, -50%)",
      },
    };
  },

  mapStyle() {
    return {
      boxSizing: `border-box`,
      border: `1px solid transparent`,
      width: `300px`,
      height: `50px`,
      borderRadius: `3px`,
      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
      fontSize: `14px`,
      outline: `none`,
      textOverflow: `ellipses`,
      position: "absolute",
      left: "48%",
      marginLeft: "-120px",
      padding: "25px",
    };
  },

  redirect(url = "") {
    window.location.href = config.public_url + url;
  },

  // date format
  dateFormat(val, format) {
    return val ? moment(val).format(config.elements.dp[format]) : "";
  },

  progressMeter(val) {
    let result = "error";
    if (val >= 30 && val < 50) {
      result = "warning";
    } else if (val >= 50 && val <= 99) {
      result = "info";
    } else if (val === 100) {
      result = "success";
    }
    return result;
  },

  trimString(str, len) {
    let res = str;
    if (str?.length > len) {
      res = str.substring(0, len) + "...";
    }
    return res;
  },

  sliderSettings() {
    return {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
  },
  getPath(url) {
    let res = url
      ?.split("/")
      ?.filter((i) => i != "")
      ?.slice(0, 2);
    return res?.[0];
  },

  htmlToString(str) {
    return str.replace(/<[^>]*>?/gm, "");
  },

  getPermission() {
    let result = [];
    types.permission.list.map((i) =>
      i.types.map((j) => {
        result.push(i.key + j);
      })
    );
    return result;
  },

  getUserPermission() {
    let result = this.getAuth();
    return result.permissions;
  },

  generateString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%:";
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },

  readable(type, str) {
    let res = "";
    if (str === null || str === "" || str === "undefined") {
      res = " - ";
    } else {
      if (type === "string") {
        res = str;
      } else if (type === "date") {
        res = moment(str).format(config.elements.dp.A);
      } else if (type === "dateTime") {
        res = moment(str).format(config.elements.dp.C);
      } else if (type === "select") {
        res = str?.name;
      } else if (type === "boolObject") {
        res = str ? "Yes" : "No";
      } else if (type === "nationality") {
        res = str?.nationality;
      }
    }
    return res;
  },

  filterSet(filterData, data) {
    let fData = {};
    let nData = common.reParse(filterData);
    Object.entries(nData).map(([key, item]) => {
      let type = types.filterFields[key].type;
      if (type === "array" && data[key] && data[key]?.length > 0) {
        fData[item.key] = data[key].map((i) => i?.value);
      } else if (type === "object") {
        fData[item.key] = data[key] ? data[key]?.value : "";
      } else if (type === "boolObject") {
        fData[item.key] = data[key] ? data[key]?.value : "";
      } else if (type === "date") {
        fData[item.key] = data[key]
          ? moment(data[key]).format(config.elements.dp.A)
          : "";
      } else if (type === "twoArray") {
        fData[item.key] = [data[key][0], data[key][1]];
      }
    });
    return fData;
  },

  filterParse(data, useKey = false) {
    let fData = {};
    let nData = common.reParse(data);
    Object.entries(nData).map(([key, item]) => {
      let finder = types.filterFields[key].finder;
      if (useKey && finder) {
        fData[useKey ? key : item.key] = common.FindSelect(
          types[finder],
          item.value
        );
      } else {
        fData[useKey ? key : item.key] = item.value;
      }
    });
    return fData;
  },
  filterParseUpdateSet(dataA, dataB) {
    let fData = {};
    let nData = common.reParse(dataA);
    Object.entries(nData).map(([key, item]) => {
      nData[key].value = dataB[key];
    });
    return nData;
  },
  filterRetrieving(dataA) {
    let data = {};
    let returnObj = dataA;
    Object.keys(dataA).map((key, item) => {
      data[key] = dataA[key]?.value;
    });

    Object.entries(this.filterSet(dataA, data)).map((key, value) => {
      Object.keys(dataA).map((itemB) => {
        if (dataA[itemB].key === key[0]) {
          returnObj[itemB].value = key[1];
        }
      });
    });

    return returnObj;
  },
  filterReset(data, useKey = false) {
    let fData = {};
    let nData = common.reParse(data);
    Object.entries(nData).map(([key, item]) => {
      fData[useKey ? key : item.key] = types.filterFields[key].value;
    });
    return fData;
  },
  accessTable(permission, permissionKey) {
    let access = [];
    let pr = {
      r: "View",
      u: "Edit",
      c: "Add",
      d: "Delete",
    };
    permission.map((val) => {
      if (permissionKey.includes(val)) {
        access.push(pr[val[val.length - 1]]);
      }
    });
    return access;
  },
};
