import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//includes
import { api, common } from "helpers";

function ResetPassword() {
  // init
  const navigate = useNavigate();
  //state
  const [init, setInit] = useState(false);
  const [localData] = useState(JSON.parse(common.localGet("passwordForgot")));
  const [viewA, SetViewA] = useState(false);
  const [viewB, SetViewB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // effect
  useEffect(() => {
    if (!localData) {
      navigate("/login");
    } else {
      setInit(true);
    }
  }, []);

  // form
  const formFields = {
    password: {
      validate: [
        "req#Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Password and confirm password do not match",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      setLoading(true);
      //api
      let data = {
        url: "auth_otp_reset_password",
        method: "POST",
        auth: "none",
        body: {
          resetToken: localData.resetToken,
          password: fields.password,
          confirmPassword: fields.confirmPassword,
        },
      };
      api.call(data, (response) => {
        if (response.status === 200) {
          common.notify("S", "Password has been reset successfully");
          common.localSet({ alert: "Password has been reset" });
          navigate("/login");
        } else if (response.status === 500) {
          setError(response.data.error);
        }
        setLoading(false);
      });
    }
  };

  return (
    init && (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 et-page-wrap py-5">
            <div className="row">
              <div className="col-md-5 mx-auto">
                <div className="box-header">
                  <p className="title">Reset Password</p>
                </div>
                <div className="box-body">
                  <label className="form-label">New Password</label>
                  <div className="et-form-row">
                    <input
                      type={viewA ? "text" : "password"}
                      name="password"
                      maxLength={15}
                      className="form-control"
                      onChange={handleChange}
                      value={fields.password}
                    />
                    <div className=" email-fieldcheckbox checkbox-design">
                      <div className="input-tick "></div>
                    </div>
                    <div
                      className="pwd-eye"
                      onClick={() => SetViewA((prev) => !prev)}
                    >
                      {viewA ? (
                        <img
                          src={common.loadImg("password_show.svg")}
                          alt="show"
                          className="et-cp"
                        />
                      ) : (
                        <img
                          src={common.loadImg("password_hide.svg")}
                          alt="hide"
                          className="et-cp"
                        />
                      )}
                    </div>
                    <p className="error-txt">{error ?? errors.password}</p>
                  </div>
                  <label className="form-label mt-3">Confirm Password</label>
                  <div className="et-form-row">
                    <input
                      type={viewB ? "text" : "password"}
                      name="confirmPassword"
                      maxLength={15}
                      className="form-control"
                      onChange={handleChange}
                      value={fields.confirmPassword}
                    />

                    <div className=" email-fieldcheckbox checkbox-design">
                      <div className="input-tick"></div>
                    </div>
                    <div
                      className="pwd-eye"
                      onClick={() => SetViewB((prev) => !prev)}
                    >
                      {viewB ? (
                        <img
                          src={common.loadImg("password_show.svg")}
                          alt="show"
                          className="et-cp"
                        />
                      ) : (
                        <img
                          src={common.loadImg("password_hide.svg")}
                          alt="hide"
                          className="et-cp"
                        />
                      )}
                    </div>
                    <p style={{ color: "red" }}>{errors.confirmPassword}</p>
                  </div>

                  <div className="d-flex justify-content-center mt-4">
                    <button
                      className="btn-default"
                      disabled={loading}
                      onClick={onSubmit}
                    >
                      {loading ? "Processing..." : "Submit"}
                    </button>
                  </div>
                </div>

                <div className="shadow-div"></div>
                <div className="greenshow-div"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ResetPassword;
