import { React, useState, useEffect, Fragment, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import Carousel from "react-bootstrap/Carousel";
import Lightbox from "react-18-image-lightbox";
//includes
import { common, api } from "helpers";
import StatusBar from "elements/statusBar";
import GoogleMaps from "elements/googleMaps";

// const
const htmlParse = require("html-react-parser");

function View() {
  // props
  let { id, idA } = useParams();
  const navigate = useNavigate();
  const refLocation = useRef(null);
  const refAbout = useRef(null);
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState([]);
  const [courseResult, setCourseResult] = useState([]);
  const [galleryView, SetGalleryView] = useState(false);
  const [galleryIndex, SetGalleryIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const [courseId, setCourseId] = useState(null);
  const [permission] = useState(common.getUserPermission());
  // effect
  useEffect(() => {
    getUniversityInfo();
  }, []);

  // api
  const getUniversityInfo = () => {
    let data = {
      url: "university",
      query: `/${id}`,
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setResult(res.data);
        setGalleryImages(
          res.data.gallery.length > 0
            ? res.data.gallery
            : ["media/no_gallery_1.png", "media/no_gallery_1.png"]
        );
        getUniversityCourseInfo();
      } else {
        setStatus("error");
      }
    });
  };

  const getUniversityCourseInfo = () => {
    let query = {
      university: id,
      tags: ["top-courses"],
    };
    // data
    let data = {
      url: "course_tag",
      query: `?query=${JSON.stringify(query)}&offset=1&1&limit=10`,
      method: "GET",
    };
    api.call(data, (res) => {
      let apiStatus = "error";
      if (res.status === 200) {
        setCourseResult(res.data);
        apiStatus = "success";
      }
      setStatus(apiStatus);
    });
  };

  //render
  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(`/university`)}
            />
            <h3 className="inner-page-header-title mb-0 me-1">Profile - </h3>
            <h3 className="inner-page-header-title mb-0">
              {status === "process" ? "Loading..." : result.name}
            </h3>
          </div>
          {status === "success" && (
            <div
              className="d-flex justify-content-between align-items-center course-card-footer course-card-footer-pd et-cp"
              onClick={() => {
                if (!result.isPublished) {
                  common.notify(
                    "I",
                    "Please verify the details and click Save & Publish button"
                  );
                }
                navigate(`/university/edit/${id}`);
              }}
            >
              {permission.includes("unu") && (
                <div className="px-5">
                  <Link className="apply-now" to="">
                    {result.isPublished ? "Edit" : "Publish"}
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="content-wrap" style={{ paddingTop: "4vh" }}>
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <Fragment>
            <div className="univ-detail-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="row row-padding et-border-bottom">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img
                            src={common.cloudImg(
                              result.logo || "media/no_university_logo.png"
                            )}
                            className="univ-view-img"
                            alt=""
                          />
                        </div>
                        <div className="ms-4">
                          <h2 className="univ-name">{result.name}</h2>
                          <p className="univ-place">{result.province?.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-padding">
                    <div className="col-md-6">
                      <label className="univ-detail-label">Country</label>
                      <p className="univ-details-text">
                        {result.country?.name ?? "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label className="univ-detail-label">City</label>
                      <p className="univ-details-text">
                        {result.city?.name ?? "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label className="univ-detail-label">Since</label>
                      <p className="univ-details-text">
                        {result.sinceYear || "-"}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <label className="univ-detail-label">Students</label>
                      <p className="univ-details-text">
                        {result.studentStrength || "-"}
                      </p>
                    </div>
                  </div>
                  <div className="row ed-bt">
                    <div className="col-md-6 row-padding-icons ed-br">
                      <p
                        className="univ-icn-p cp"
                        onClick={() =>
                          result.brochureDocs
                            ? common.openCloudUrl(result.brochureDocs)
                            : null
                        }
                      >
                        <img src={common.loadImg("doc-icn.svg")} alt="" />
                        <span>View Brochure PDF</span>
                      </p>
                    </div>
                    <div className="col-md-3 row-padding-icons  ed-br">
                      <p
                        className="univ-icn-p cp"
                        onClick={() =>
                          result.email
                            ? window.open(`mailto:${result.email}`)
                            : null
                        }
                      >
                        <img src={common.loadImg("msg-icn.svg")} alt="" />
                        <span>Email</span>
                      </p>
                    </div>
                    <div className="col-md-3 row-padding-icons">
                      <p
                        className="univ-icn-p cp"
                        onClick={() => {
                          common.scrollTo("bottom");
                        }}
                      >
                        <img
                          src={common.loadImg("iconly-light-location.png")}
                          alt=""
                        />
                        <span>Location</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="univ-slider">
                    <Carousel>
                      {galleryImages.map((i, k) => (
                        <Carousel.Item key={k}>
                          <img
                            className="univ-img d-block"
                            src={common.cloudImg(i)}
                            alt="First slide"
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <button
                      className="btn btn-view-photo"
                      onClick={() => SetGalleryView(true)}
                    >
                      <img src={common.loadImg("btn-img.svg")}></img>
                      <span className="ps-2">View Photo</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="form-title-step3" ref={refAbout}>
              About University
            </h2>
            <div className="row-wrap-step3 editor-content-wrap">
              {result.description
                ? htmlParse(result.description)
                : "Description not added"}
            </div>
            <h2 className="form-title-step3">Features</h2>
            <div className="row-wrap-step3 editor-content-wrap">
              {result.features
                ? htmlParse(result.features)
                : "Features not added"}
            </div>

            <div className="right-content-width">
              <div className="d-flex align-items-center justify-content-between">
                <h2 className="form-title-step3">Courses</h2>
              </div>
              {courseResult.data.length > 0 ? (
                <div className="right-content-width">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-slider courses-slider-wrap">
                        <Slider {...common.sliderSettings()}>
                          {courseResult.data.map((i, k) => (
                            <div className="card-margin" key={k}>
                              <div className="course-card">
                                <div className="course-card-header university-header">
                                  <div className="">
                                    <h3 className="card-course-title ">
                                      {i.name}
                                    </h3>
                                  </div>
                                  <span className="course-tag me-3">
                                    {i.courseLevel}
                                  </span>
                                  <span className="course-tag bg-gold">
                                    {i.courseDurationType} Year
                                  </span>
                                </div>
                                <div className="d-flex align-items-center justify-content-between course-card-university-wrap university-logo-cont">
                                  <div className="d-flex align-items-center">
                                    <img
                                      src={common.loadImg(
                                        "oxford-university-icon.svg"
                                      )}
                                      alt=""
                                    />
                                    <p className="card-university-name ps-3">
                                      <span className="card-university-title">
                                        {result.name}
                                      </span>
                                      <br />
                                      <span className="card-university-place">
                                        {result.province?.name}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                                <div className="card-course-desc max-lines h-max-width">
                                  <p>{common.trimString(i.shortDesc, 100)}</p>
                                </div>
                                <div className="d-flex justify-content-between align-items-center course-card-footer">
                                  <div>
                                    <p className="label">Fee</p>
                                    <p className="amount">
                                      {i.currency} {i.tuitionFee}
                                    </p>
                                  </div>
                                  <div>
                                    <a
                                      className="apply-now"
                                      onClick={() => setCourseId(i._id)}
                                    >
                                      Apply Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                          {courseResult.totalCourse > 7 && (
                            <div className="d-flex align-items-center justify-content-center view-more-padding course-card">
                              <div
                                className="et-cp"
                                onClick={() => navigate("/course/finder")}
                              >
                                <div className="rounded-btn ms-4">
                                  <img
                                    src={common.loadImg("more-course.svg")}
                                  />
                                </div>
                                <p className="label-slider">
                                  View more <br />
                                  Courses
                                </p>
                              </div>
                            </div>
                          )}
                        </Slider>
                      </div>
                    </div>
                  </div>
                  <div className="br-white"></div>
                </div>
              ) : (
                <div className="row-wrap-step3 editor-content-wrap">
                  Course not added
                </div>
              )}
            </div>

            <h2 className="form-title-step3">Institutional details</h2>
            <div className="row-wrap-step3 editor-content-wrap">
              {result.institutionDetail
                ? htmlParse(result.institutionDetail)
                : "Institutional details not added"}
            </div>
            <h2 className="form-title-step3" ref={refLocation}>
              Location details
            </h2>
            {result.coordinate && (
              <GoogleMaps
                coordinate={result.coordinate}
                zoom={10}
                search={false}
                dragMarker={false}
                clickMarker={false}
                markerShow={true}
                markerText={`${result.location || "Address not added"}`}
              />
            )}
            <div className="inner-footer">
              <p className="univ-detail-p mb-0">{result.name}</p>
              <p className="univ-detail-p mb-0">{`${result.location}`}</p>
            </div>
            {galleryView && (
              <Lightbox
                mainSrc={common.cloudImg(galleryImages[galleryIndex])}
                nextSrc={common.cloudImg(
                  galleryImages[(galleryIndex + 1) % galleryImages.length]
                )}
                prevSrc={common.cloudImg(
                  galleryImages[
                    (galleryIndex + galleryImages.length - 1) %
                      galleryImages.length
                  ]
                )}
                onCloseRequest={() => SetGalleryView(false)}
                onMovePrevRequest={() =>
                  SetGalleryIndex(
                    (galleryIndex + galleryImages.length - 1) %
                      galleryImages.length
                  )
                }
                onMoveNextRequest={() =>
                  SetGalleryIndex((galleryIndex + 1) % galleryImages.length)
                }
              />
            )}
          </Fragment>
        )}
      </div>
    </Fragment>
  );
}

export default View;
