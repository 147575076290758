import moment from "moment";
import { Fragment, useEffect, useRef, useState } from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
// mui
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import Switch from "@mui/material/Switch";
// bootstrap
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import ProgressBar from "react-bootstrap/ProgressBar";
// includes
import AgFilter from "elements/AgFilter";
import StatusTable from "elements/statusTable";
import { api, common, config, types } from "helpers";
// files
import agModuleList from "assets/files/agModule.json";
import { useNavigate } from "react-router-dom";

// filter
const AgTable = (props) => {
  let ref = useRef();
  const navigate = useNavigate();
  let filter = common.localGet(props?.module)
    ? JSON.parse(common.localGet(props?.module))
    : {};
  // props
  const agModule = agModuleList[props.module];
  // states
  const [isInit, setInit] = useState(false);
  const [status, setStatus] = useState("process");
  const [loader, setLoader] = useState(false);
  const [reload, setReload] = useState(false);
  const [result, setResult] = useState({
    data: [],
    totalResults: 0,
    totalRecords: 0,
  });
  const [sliderRange, setSliderRange] = useState([0, 100]);
  const [selected, setSelected] = useState([]);
  const [sort, setSort] = useState({
    sortBy: agModule.sortBy[0],
    sortDir: agModule.sortBy[1],
  });
  const [paging, setPaging] = useState(
    common.localGet(props?.module + "paging")
      ? JSON.parse(common.localGet(props?.module + "paging"))
      : { offset: 1, limit: 10 }
  );
  // filter
  const [search, setSearch] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  const [filterModifiedData, setFilterModifiedData] = useState(
    props?.filterData ?? {}
  );
  const [filterData, setFilterData] = useState(
    Object.keys(filter).length > 0
      ? common.filterSet(props.filterData, filter)
      : common.filterParse(props.filterData)
  );
  const [filterQuery] = useState(props.query || {});
  const [filterReset, setFilterReset] = useState(false);
  // action
  const [actionData, setActionData] = useState(null);
  const [crmLoading, setCrmLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState();

  // effect
  useEffect(() => {
    loadData();
  }, [props]);

  useEffect(() => {
    if (isInit && reload) {
      loadData();
    }
  }, [reload]);

  useEffect(() => {
    if (isInit) {
      setReload(true);
    }
    setSelected([]);
  }, [props.reload]);

  useEffect(() => {
    if (isInit) {
      const tId = setTimeout(() => {
        setReload(true);
      }, 500);
      return () => clearTimeout(tId);
    }
  }, [search]);

  // api
  const loadData = () => {
    if (isInit) {
      setLoader(true);
      setReload(false);
      setFilterReset(false);
    }
    // filter
    let query = `?sortBy=${
      agModule.fields[sort.sortBy]?.sortKey ?? sort.sortBy
    }&sortDir=${sort.sortDir}`;
    query += `&offset=${paging.offset}&limit=${paging.limit}`;
    query += `&query=${JSON.stringify({
      search,
      ...filterData,
      ...filterQuery,
    })}`;
    // params
    let data = {
      url: agModule.api,
      method: "GET",
      query,
    };
    // call
    api.call(data, (res) => {
      if (res.status === 200) {
        let rData = res.data;
        setResult({
          data: rData.data,
          totalResults: rData.pageInfo.totalCount,
          totalRecords: rData.pageInfo.totalCount,
        });
        if (rData.pageInfo?.maxPrice) {
          if (!isInit && props?.modifyFilterData) {
            setFilterModifiedData((prev) => ({
              ...prev,
              feesSlider: {
                ...prev.feesSlider,
                value: [0, rData.pageInfo?.maxPrice],
              },
            }));
          }
          setSliderRange((prev) => [0, rData.pageInfo?.maxPrice]);
        }
        if (rData.data.length > 0) {
          setStatus("success");
        } else {
          setStatus("empty");
        }
      } else {
        setStatus("error");
      }
      if (!isInit) {
        setInit(true);
      } else {
        setLoader(false);
      }
    });
  };

  const statusUpdate = (e, key, id) => {
    let data = {
      url: agModule.api,
      method: "PUT",
      query: `/${id}`,
      body: {
        [agModule.fields[key].update]: e.target.checked,
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Record has been updated");
      }
    });
  };

  // sort
  const handleSort = (field) => {
    setSort({
      sortBy: field,
      sortDir:
        sort.sortBy === field
          ? sort.sortDir === "asc"
            ? "desc"
            : "asc"
          : "asc",
    });
    setReload(true);
  };

  // filter
  const handleFilter = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPaging({ offset: 1, limit: 10 });
  };

  // select
  const handleSelect = (e) => {
    const { checked, value } = e.target;
    setSelected((pData) => {
      let nData = common.reParse(pData);
      if (checked) {
        nData.push(value);
      } else {
        nData.splice(nData.indexOf(value), 1);
      }
      return nData;
    });
  };

  const handleSelectAll = (e) => {
    const { checked } = e.target;
    setSelected((pData) => {
      let nData = common.reParse(pData);
      if (checked) {
        result.data.map((i) => {
          if (!nData.includes(i._id)) {
            nData.push(i._id);
          }
        });
      } else {
        nData = [];
      }
      return nData;
    });
  };

  // pagination
  const handlePaging = (no) => {
    if (paging.offset != no) {
      setPaging((prev) => {
        // { ...prev, offset: no },
        let val = { ...prev, offset: no };
        common.localSet(props.module + "paging", val);
        return val;
      });
      setReload(true);
    }
  };

  const handleLimit = (limit) => {
    if (paging.limit != limit) {
      setPaging({ offset: 1, limit });
      common.localSet(props.module + "paging", { offset: 1, limit });
      setReload(true);
    }
  };

  // action
  const handleActionCallBack = (i) => {
    let query = `?sortBy=${
      agModule.fields[sort.sortBy]?.sortKey ?? sort.sortBy
    }&sortDir=${sort.sortDir}`;
    query += `&offset=${paging.offset}&limit=${paging.limit}`;
    query += `&query=${JSON.stringify({
      search,
      ...filterData,
      ...filterQuery,
    })}`;
    props?.onAction({
      type: i,
      item: actionData,
      query,
    });
    setActionData(null);
  };

  // refresh
  const handleRefresh = () => {
    setSort({
      sortBy: agModule.sortBy[0],
      sortDir: agModule.sortBy[1],
    });
    setSelected([]);
    setPaging({ offset: 1, limit: 10 });
    // action
    setActionData(null);
    // reload
    setLoader(true);
    // filter
    setFilterModal(false);
    setSearch("");
    setFilterData(
      Object.keys(filter).length > 0
        ? common.filterSet(props.filterData, filter)
        : common.filterParse(props.filterData)
    );
    if (props?.onGetFilterQuery) {
      props?.onGetFilterQuery(common.filterReset(props.filterData));
    }

    setFilterReset(true);
    setReload(true);
  };

  // link
  const handleCallBack = (type, item) => {
    props?.onAction({
      type,
      item,
    });
  };

  const permission = () => {
    //props?.actionPermission?.length;

    if (agModule?.action?.length) {
      if (props?.actionPermission?.length) {
        if (
          props?.actionPermission?.filter((value) =>
            agModule?.action.includes(value)
          ).length
        ) {
          return "permission_action";
        } else {
          return "permission_action_empty";
        }
      } else {
        return "action";
      }
    } else {
      return "no_action";
    }
  };

  // support
  const readable = (type, str) => {
    let res = "";
    if (str === null || str === "" || typeof str === "undefined") {
      res = " - ";
    } else {
      if (type === "string") {
        res = str;
      } else if (type === "email") {
        // res = common.hideEmail(str);
        res=str
      } else if (type === "mobile") {
        // res = common.hideMobile(str);
        res=str
      } else if (type === "link") {
        res = str;
      } else if (type === "date") {
        res = moment(str).format(config.elements.dp.A);
      } else if (type === "dateTime") {
        res = moment(str).format(config.elements.dp.C);
      } else if (type === "select") {
        res = str?.name;
      } else if (type === "status") {
        res = str ? "Active" : "Inactive";
      } else if (type === "statusButton") {
        res = types.applicationStatus[str];
      }
    }
    return res;
  };

  const verifyChecked = () => {
    let i = 0;
    result.data.map((item) => {
      if (selected.includes(item._id)) {
        ++i;
      }
    });
    return i > 0 && result.data.length === i;
  };

  // filter
  const onFilterSuccess = (data, type) => {
    setFilterData(common.filterSet(props.filterData, data));
    if (type == "submit") {
      setFilterModal(false);
    }
    setReload(true);
    if (props?.onGetFilterQuery) {
      props?.onGetFilterQuery(common.filterSet(props.filterData, data));
    }
    common.localSet(props.module, data);
    common.localSet(props.module + "paging", { offset: 1, limit: 10 });
  };

  const addToCrm = (item) => {
    setSelectedItemId(item?._id);
    setCrmLoading(true);
    let data = {
      url: "enquiry",
      method: "POST",
      body: item,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", `Verify successfully`);
        navigate("/enquiry");
      } else if (res.data.error) {
        common.notify("E", res.data.error);
      } else {
        common.notify("E", "Something went wrong");
      }
      setCrmLoading(false);
    });
  };

  let isFilter =
    Object.keys(filterData).length > 0 &&
    Object.keys(filterData).find((key) =>
      typeof filterData[key] == "object"
        ? filterData[key].length > 0
        : filterData[key] !== ""
    );

  return (
    <Fragment>
      {agModule.title && (
        <div className="title-wrap d-flex justify-content-between align-items-center">
          <h1 className="page-title">{agModule.title}</h1>
        </div>
      )}
      <div
        className={agModule.wrap ? "content-wrap" : "content-wrap-none"}
        style={{ paddingTop: agModule.wrap }}
      >
        <div className="container-fluid">
          {agModule.subTitle && (
            <h2 className="sub-title">{agModule.subTitle}</h2>
          )}
          <div className="filter-wrap d-flex justify-content-between align-items-center">
            {agModule.search && (
              <div className="search-filter me-4">
                <input
                  type="text"
                  name="search"
                  id="search"
                  value={search}
                  placeholder={agModule.search}
                  className="form-control search-input"
                  onChange={handleFilter}
                />
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <div className="filter-options me-3">
                {agModule.massDelete && selected.length > 0 && (
                  <button
                    className="btn-filter"
                    onClick={() => handleCallBack("massdelete", selected)}
                  >
                    <DeleteIcon />
                  </button>
                )}
              </div>
              <div className="filter-options me-3">
                {/* {selected.length > 0 && (
                  <button className="btn-filter">
                    <DeleteIcon />
                  </button>
                )} */}
                {agModule.refresh && (
                  <button className="btn-filter" onClick={handleRefresh}>
                    <RefreshIcon />
                  </button>
                )}
              </div>
              {Object.keys(filterData).length > 0 && (
                <>
                  {isFilter == undefined ? (
                    <div className="filter-options me-3 position-relative">
                      <button
                        className="btn-filter"
                        onClick={() => setFilterModal(true)}
                      >
                        <img
                          src={common.loadImg("filter-icn.svg")}
                          alt="Search"
                        />
                      </button>
                    </div>
                  ) : (
                    <div className="filter-options me-3 position-relative">
                      <button
                        className="btn-filter"
                        onClick={() => setFilterModal(true)}
                      >
                        <img
                          src={common.loadImg("filter-active.svg")}
                          alt="Search"
                        />
                      </button>
                      <button
                        className="btn-filter"
                        onClick={() => ref.current.log()}
                      >
                        <img
                          src={common.loadImg("filter-clear.svg")}
                          alt="Search"
                        />
                      </button>
                    </div>
                  )}
                </>
              )}

              {agModule.button &&
                (permission() === "permission_action" ||
                permission() === "permission_action_empty" ? (
                  <>
                    {props?.actionPermission?.includes("Add") ? (
                      <button
                        className="btn btn-green btn-add"
                        onClick={() => handleActionCallBack("Add")}
                      >
                        {agModule.button}
                      </button>
                    ) : null}
                  </>
                ) : (
                  <button
                    className="btn btn-green btn-add"
                    onClick={() => handleActionCallBack("Add")}
                  >
                    {agModule.button}
                  </button>
                ))}
              {agModule.externalButton && (
                <button
                  className="btn btn-green btn-add"
                  onClick={() => handleActionCallBack("Export")}
                >
                  {agModule.externalButton}
                </button>
              )}
            </div>
          </div>
          <div className="table-responsive position-relative">
            <table className="table table-bordered et-data-table">
              <thead>
                <tr>
                  {agModule.multiCheck && (
                    <th>
                      <input
                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        checked={verifyChecked()}
                        onChange={(e) => handleSelectAll(e)}
                        className="input-checkbox"
                      />
                    </th>
                  )}
                  {Object.entries(agModule.fields).map(([key, item]) => (
                    <th
                      key={key}
                      className={
                        item.sort
                          ? `sorting${
                              sort.sortBy === key ? ` sort-${sort.sortDir}` : ""
                            }`
                          : ""
                      }
                      onClick={() => item.sort && handleSort(key)}
                    >
                      {item.label}
                    </th>
                  ))}
                  {/* {props.module == "adminEnquiry" && <th>Verify</th>} */}
                  {["permission_action", "action"].includes(permission()) && (
                    <th>Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {status !== "success" ? (
                  <tr>
                    <td colSpan={agModule.columns}>
                      <StatusTable status={status} />
                    </td>
                  </tr>
                ) : (
                  result.data.map((item, key) => (
                    <tr key={key}>
                      {agModule.multiCheck && (
                        <td>
                          <input
                            type="checkbox"
                            name="selectAll"
                            id="selectAll"
                            value={item._id}
                            className="input-checkbox"
                            checked={selected.includes(item._id)}
                            onChange={handleSelect}
                          />
                        </td>
                      )}
                      {Object.entries(agModule.fields).map(([cKey, cItem]) => (
                        <Fragment key={key + cKey}>
                          {cItem.type === "studentName" ? (
                            <td>{`${item?.firstName} ${
                              item?.lastName ? item?.lastName : ""
                            }`}</td>
                          ) : cItem.type == "metaKey" ? (
                            <td>{`${item?.metaKey}`}</td>
                          ) : cItem.type === "category" ? (
                            <td>{`${item?.name}`}</td>
                          ) : cItem.type === "studentProfileName" ? (
                            <td>{`${item?.profile?.firstName} ${
                              item?.profile?.lastName
                                ? item?.profile?.lastName
                                : ""
                            }`}</td>
                          ) : cItem.type === "agentName" ? (
                            <td>{`${item?.firstName} ${item?.lastName}`}</td>
                          ) : cItem.type === "agentObject" ? (
                            <td>{`${item.agent?.firstName} ${
                              item.agent?.lastName ? item.agent?.lastName : ""
                            }`}</td>
                          ) : cItem.type === "studentObj" ? (
                            <td>{`${item.student?.firstName} ${
                              item.student?.lastName
                                ? item.student?.lastName
                                : ""
                            }`}</td>
                          ) : cItem.type === "courseObj" ? (
                            <td>{`${item.course?.name}`}</td>
                          ) : cItem.type === "universityObj" ? (
                            <td>{`${item.university?.name}`}</td>
                          ) : cItem.type === "businessName" ? (
                            <td>{`${item.businessName || "_"}`}</td>
                          ) : cItem.type === "registeredFrom" ? (
                            <td>{`${item.registeredFrom || "_"}`}</td>
                          ) : cItem.type === "courseFees" ? (
                            <td>{`${item.currency} ${item.tuitionFee}`}</td>
                          ) : cItem.type === "agentEmail" ? (
                            <td>{item?.agent?.firstName || "_"}</td>
                          ) : cItem.type === "catObj" ? (
                            <td>{item?.category[0]?.name || "_"}</td>
                          ) : cItem.type === "subAgentEmail" ? (
                            <td>
                              {(item?.subagent[0]?.firstName &&
                                `${
                                  item?.subagent[0]?.firstName
                                    ?.charAt(0)
                                    .toUpperCase() +
                                  item?.subagent[0]?.firstName?.slice(1)
                                }`) ||
                                "-"}
                            </td>
                          ) : cItem.type === "courseName" ? (
                            <td>{item.course.name || "-"}</td>
                          ) : cItem.type === "universityName" ? (
                            <td>{item.name || "-"}</td>
                          ) : cItem.type === "sortOrder" ? (
                            <td>{item.sortOrder || "-"}</td>
                          ) : cItem.type === "studentObject" ? (
                            <td>{item?.student[0]?.registeredFrom || "-"}</td>
                          ) : cItem.type === "applicationAgentName" ? (
                            <td>
                              {(item?.agent[0]?.firstName &&
                                `${
                                  item?.agent[0]?.firstName
                                    ?.charAt(0)
                                    .toUpperCase() +
                                  item?.agent[0]?.firstName?.slice(1)
                                }`) ||
                                "-"}
                            </td>
                          ) : cItem.type === "applicationSubAgentName" ? (
                            <td>
                              {(item?.subagent[0]?.firstName &&
                                `${
                                  item?.subagent[0]?.firstName
                                    ?.charAt(0)
                                    .toUpperCase() +
                                  item?.subagent[0]?.firstName?.slice(1)
                                }`) ||
                                "-"}
                            </td>
                          ) : cItem.type === "applicationStudentName" ? (
                            <td>
                              {(item?.profile?.firstName &&
                                `${
                                  item?.profile?.firstName
                                    ?.charAt(0)
                                    .toUpperCase() +
                                  item?.profile?.firstName?.slice(1)
                                }${
                                  " " + item?.profile?.lastName.toUpperCase()
                                }`) ||
                                "-"}
                            </td>
                          ) : cItem.type === "universityLogo" ? (
                            <td width={cItem?.width}>
                              {item.logo ? (
                                <img
                                  className="et-img"
                                  src={
                                    item.logo ? common.cloudImg(item.logo) : ""
                                  }
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                          ) : cItem.type === "universityNameObject" ? (
                            <td>{item.university?.name || "-"}</td>
                          ) : cItem.type === "universityLogoObject" ? (
                            <td width={cItem?.width}>
                              {item.university?.logo ? (
                                <img
                                  className="et-img"
                                  src={common.cloudImg(item.university.logo)}
                                />
                              ) : (
                                "-"
                              )}
                            </td>
                          ) : cItem.type === "universityNameLogo" ? (
                            <td>
                              <img
                                className="et-img"
                                src={
                                  item.logo ? common.cloudImg(item.logo) : ""
                                }
                              />
                              <span>{item.name}</span>
                            </td>
                          ) : cItem.type === "universityNameLogoObject" ? (
                            <td>
                              <img
                                className="et-img"
                                src={
                                  item.university?.logo
                                    ? common.cloudImg(item.university?.logo)
                                    : ""
                                }
                              />
                              <span>{item.university?.name}</span>
                            </td>
                          ) : cItem.type === "agentSubscription" ? (
                            <td>
                              <span>
                                {item?.subscriptions
                                  ? item?.subscriptions?.title
                                  : "-"}
                              </span>
                            </td>
                          ) : cItem.type === "agentCreatedBy" ? (
                            <td>
                              <span>
                                {item?.agentCreatedBy
                                  ? item?.agentCreatedBy?.firstName
                                  : item?.adminCreatedBy
                                  ? item?.adminCreatedBy.firstName
                                  : "-"}
                              </span>
                            </td>
                          ) : cItem.type === "promoValueType" ? (
                            <td>
                              <span>
                                {item?.type === "percentage"
                                  ? `${item?.value}%`
                                  : `${item?.value} ${item?.type}`}
                              </span>
                            </td>
                          ) : cItem.type === "promoCategory" ? (
                            <td>
                              <span>
                                {
                                  common.FindSelect(
                                    types.promoCategory,
                                    item.category
                                  )?.label
                                }
                              </span>
                            </td>
                          ) : cItem.type === "isPublished" ? (
                            <td>
                              <span
                                className={`alert et-status et-status-${
                                  item.isPublished ? "active" : "inactive"
                                }`}
                              >
                                {item.isPublished ? "Published" : "Draft"}
                              </span>
                            </td>
                          ) : cItem.type === "isActive" ? (
                            <td>
                              <span
                                className={`alert et-status et-status-${
                                  item.isActive ? "active" : "inactive"
                                }`}
                              >
                                {item.isActive ? "Yes" : "No"}
                              </span>
                            </td>
                          ) : cItem.type === "isActiveSwitch" ? (
                            <td>
                              {loader ? (
                                "-"
                              ) : (
                                <Switch
                                  defaultChecked={item.isActive}
                                  color="secondary"
                                  onChange={(e) =>
                                    statusUpdate(e, cKey, item._id)
                                  }
                                />
                              )}
                            </td>
                          ) : cItem.type === "isApproved" ? (
                            <td>
                              <span
                                className={`alert et-status et-status-${
                                  item.isApproved ? "active" : "inactive"
                                }`}
                              >
                                {item.isApproved ? "Yes" : "No"}
                              </span>
                            </td>
                          ) : cItem.type === "isVerified" ? (
                            <td>
                              <span
                                className={`alert et-status et-status-${
                                  item.isVerified ? "active" : "inactive"
                                }`}
                              >
                                {item.isVerified ? "Yes" : "No"}
                              </span>
                            </td>
                          ) : cItem.type === "isPromotor" ? (
                            <td>
                              <span
                                className={`alert et-status et-status-${
                                  item.isPromotor ? "active" : "inactive"
                                }`}
                              >
                                {item.isPromotor ? "Yes" : "No"}
                              </span>
                            </td>
                          ) : cItem.type === "isVerifiedSwitch" ? (
                            <td>
                              {loader ? (
                                "-"
                              ) : (
                                <Switch
                                  defaultChecked={item.isVerified}
                                  color="secondary"
                                  onChange={(e) =>
                                    statusUpdate(e, cKey, item._id)
                                  }
                                />
                              )}
                            </td>
                          ) : cItem.type === "progress" ? (
                            <td>
                              <ProgressBar
                                variant={common.progressMeter(item[cKey])}
                                now={item[cKey]}
                                label={item[cKey]}
                              />
                            </td>
                          ) : cItem.type === "applyButton" ? (
                            <td>
                              {item.profileProgress === 100 ? (
                                <button
                                  className="btn btn-submit btn-paynow"
                                  onClick={() => handleCallBack("Apply", item)}
                                >
                                  {cItem.label}
                                </button>
                              ) : (
                                "-"
                              )}
                            </td>
                          ) : cItem.type === "paymentButton" ? (
                            <td>
                              <button
                                className="btn btn-submit btn-paynow"
                                onClick={() => handleCallBack("Payment", item)}
                              >
                                Pay now
                              </button>
                            </td>
                          ) : cItem.type === "link" ? (
                            <td>
                              <span
                                className="cp"
                                onClick={() => handleCallBack(cKey, item)}
                              >
                                {readable(cItem.type, item[cKey])}
                              </span>
                            </td>
                          ) : cItem.type === "statusButton" ? (
                            <td>
                              <span
                                className={`alert et-status et_status_${item[cKey]}`}
                              >
                                {readable(cItem.type, item[cKey])}
                              </span>
                            </td>
                          ) : (
                            <td>{readable(cItem.type, item[cKey])}</td>
                          )}
                        </Fragment>
                      ))}
                      {props.module == "adminEnquiry" &&
                        [
                          "winback",
                          "weblead",
                          "referafriend",
                          "promoter",
                        ].includes(item.domain) && (
                          <td>
                            {item.isVerify == false ? (
                              <button
                                onClick={() => addToCrm(item)}
                                className="verify-btn"
                                disabled={
                                  crmLoading && selectedItemId == item._id
                                    ? true
                                    : false
                                }
                              >
                                {crmLoading && selectedItemId == item._id
                                  ? "Processing..."
                                  : "Verify"}
                              </button>
                            ) : (
                              "Verified"
                            )}
                          </td>
                        )}
                      {["permission_action", "action"].includes(
                        permission()
                      ) && (
                        <td>
                          <OverlayTrigger
                            trigger={"click"}
                            placement={"bottom"}
                            containerPadding={5}
                            rootClose={true}
                            rootCloseEvent={"click"}
                            onToggle={() =>
                              actionData ? setActionData(null) : null
                            }
                            show={actionData?._id == item._id}
                            overlay={
                              <Popover id="popover-contained">
                                <Popover.Header as="h3">Actions</Popover.Header>
                                <Popover.Body className="p-0">
                                  <ul className="page-count-list">
                                    {permission() === "permission_action" ? (
                                      <>
                                        {agModule.action.map((i, k) => {
                                          if (
                                            props?.actionPermission?.includes(i)
                                          ) {
                                            return (
                                              <li
                                                key={k}
                                                onClick={() =>
                                                  handleActionCallBack(i)
                                                }
                                              >
                                                <Link to="">{i}</Link>
                                              </li>
                                            );
                                          }
                                        })}
                                      </>
                                    ) : (
                                      <>
                                        {agModule.action.map((i, k) => (
                                          <li
                                            key={k}
                                            onClick={() =>
                                              handleActionCallBack(i)
                                            }
                                          >
                                            <Link to="">{i}</Link>
                                          </li>
                                        ))}
                                      </>
                                    )}
                                    {/* {agModule.action.length > 0 && agModule.action.includes("Member") && (
                                      <li
                                        onClick={() => navigate("/member/"+item._id)}
                                      >
                                        <Link to="">{"Member"}</Link>
                                      </li>
                                    )} */}
                                  </ul>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <div className="position-relative">
                              <button
                                className="btn-action"
                                onClick={() =>
                                  setTimeout(() => {
                                    setActionData(item);
                                  }, 50)
                                }
                              >
                                <img
                                  src={common.loadImg("more-icn.svg")}
                                  alt=""
                                />
                              </button>
                            </div>
                          </OverlayTrigger>
                        </td>
                      )}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
            {loader && (
              <div className="et-loader-wrap">
                <StatusTable status="process" />
              </div>
            )}
            <div className="table-footer-wrap">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <p className="entry-count mb-0">
                    Showing{" "}
                    {paging.offset === 1
                      ? paging.offset
                      : (paging.offset - 1) * paging.limit}{" "}
                    to{" "}
                    {paging.offset * paging.limit >= result.totalResults
                      ? result.totalResults
                      : paging.offset * paging.limit}{" "}
                    of {result.totalResults} entries
                  </p>
                </div>
                <div className="col-md-6 d-flex justify-content-end align-items-center">
                  <div className="pagination-wrap me-3">
                    {result.totalResults > 0 && (
                      <Pagination
                        itemClass="ag-page-item"
                        linkClass="ag-page-link"
                        activePage={paging.offset}
                        itemsCountPerPage={paging.limit}
                        totalItemsCount={result.totalResults}
                        pageRangeDisplayed={5}
                        onChange={handlePaging}
                      />
                    )}
                  </div>
                  <select
                    className="form-select page-per-view"
                    value={paging.limit}
                    onChange={(e) => handleLimit(e.target.value)}
                  >
                    <option value="10">10/page</option>
                    <option value="20">20/page</option>
                    <option value="30">30/page</option>
                    <option value="50">50/page</option>
                    <option value="100">100/page</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {Object.keys(filterData).length > 0 && (
        <AgFilter
          ref={ref}
          module={props.module}
          show={filterModal}
          filterData={filterModifiedData}
          filterReset={filterReset}
          onClose={() => setFilterModal(false)}
          onSuccess={onFilterSuccess}
          filterSession={props?.filterSession}
          sliderRange={sliderRange}
        />
      )}
    </Fragment>
  );
};

export default AgTable;
